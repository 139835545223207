import React from "react"
import { withTrans } from '../../i18n/withTrans'

const availableLocales = ["es", "en"];

const Layout = ({ children, t, i18n, locale }) => {
    const isBrowser = typeof window !== "undefined"
    let langLoaded = !isBrowser;
    
    if (isBrowser) {
      // Locale is loaded via the components (via the web url) and i18n loads default locale from browser.
      // If it's different redirect to browser locale. If we use `changeLanguage`, we change i18n so
      // The url and the i18n locale will be the same.
      const languageCode = i18n.language?.slice(0, 2);
      if (
        availableLocales.includes(languageCode) &&
        languageCode !== locale &&
        "privacy" !== locale
      ) {
        window.location.replace(
          `/${languageCode !== "en" ? languageCode : ""}`
        );
      } else {
        langLoaded = true;
      }
    }
  
    return !langLoaded 
        ? null
        : (<>{children}</>)
}

export default withTrans(Layout)
