import React from 'react';
// import { useStaticQuery, graphql } from 'gatsby';
// import Box from 'common/components/Box';
// import Text from 'common/components/Text';
// import Image from 'common/components/Image';
import Logo from 'common/components/UIElements/Logo';
// import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import FooterArea, {
  // WidgetArea,
  MenuArea,
  // Menu,
  // MenuItem,
  CopyrightText,
} from './footer.style';

import logoImage from 'common/assets/image/rutina/icon.png';

import { useTranslation } from "react-i18next"

const Footer = ({passedLocale}) => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     appClassicJson {
  //       footer {
  //         logo {
  //           publicURL
  //         }
  //         menu {
  //           id
  //           link
  //           text
  //         }
  //         widgets {
  //           id
  //           icon {
  //             publicURL
  //           }
  //           title
  //           description
  //         }
  //       }
  //     }
  //   }
  // `);
  // const { logo, menu, widgets } = data.appClassicJson.footer;

  const date = new Date();
  const year = date.getFullYear();

  const { i18n } = useTranslation();

  const changeLanguage = (evt) => {
    i18n.changeLanguage(evt.target.value)
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  return (
    <FooterArea>
      <Container>
        {/* <WidgetArea>
          {widgets.map((item) => (
            <Box className="col" key={`footer-widget--key${item.id}`}>
              <Image src={item.icon.publicURL} alt={item.title} />
              <Heading as="h3" content={item.title} />
              <Text content={item.description} />
            </Box>
          ))}
        </WidgetArea> */}
        {/* End of footer widgets area */}
        <MenuArea>
          <Logo
            className="logo"
            href="/#"
            logoSrc={logoImage}
            title="App Classic"
            logoStyle={{ width: 'auto', height: 42 }}
          />
          <select name="languages" id="languages" value={passedLocale} onChange={changeLanguage}>
            <option key={'en'} value={'en'} selected={passedLocale === 'en'}>English</option>
            <option key={'es'} value={'es'} selected={passedLocale === 'es'}>Español</option>
          </select>

          {/* <Menu>
            {menu.map((item) => (
              <MenuItem key={`footer-link${item.id}`}>
                <a href={item.link}>{item.text}</a>
              </MenuItem>
            ))}
          </Menu> */}
          <CopyrightText><a href="/privacy">Privacy Policy</a> | Copyright {year} (<a href="mailto:contact@rutina.info">contact@rutina.info</a>)</CopyrightText>
          <CopyrightText><a target="_blank" href="https://icons8.com/icon/105177/whistle">Whistle</a>, <a target="_blank" href="https://icons8.com/icon/114449/personal-trainer">Personal Trainer</a>, <a target="_blank" href="https://icons8.com/icon/42824/list">List</a>, <a target="_blank" href="https://icons8.com/icon/30324/queue">Queue</a>, <a target="_blank" href="https://icons8.com/icon/107687/weight">Weight</a>, <a target="_blank" href="https://icons8.com/icon/47783/list">List</a>, <a target="_blank" href="https://icons8.com/icon/43415/user-folder">User Folder</a>, <a target="_blank" href="https://icons8.com/icon/42450/pdf">PDF</a>, <a target="_blank" href="https://icons8.com/icon/48371/contact-details">Contact Details</a> icons by <a target="_blank" href="https://icons8.com/">Icons8</a></CopyrightText>
        </MenuArea>
        {/* End of footer menu area */}
      </Container>
    </FooterArea>
  );
};

export default Footer;
