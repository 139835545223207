import React, { useState, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import ScrollSpyMenu from 'common/components/ScrollSpyMenu';
import Text from 'common/components/Text';
// import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Icon } from 'react-icons-kit';
import { menu } from 'react-icons-kit/feather/menu';
import { x } from 'react-icons-kit/feather/x';
// import { search } from 'react-icons-kit/feather/search';
import Logo from 'common/components/UIElements/Logo';
import Button from 'common/components/Button';
import Container from 'common/components/UI/Container';
import useOnClickOutside from 'common/hooks/useOnClickOutside';
import NavbarWrapper, { MenuArea, MobileMenu, Search } from './navbar.style';

const Navbar = () => {
  const data = useStaticQuery(graphql`
    query {
      rutinaJson {
        navbar {
          logo {
            publicURL
          }
          navMenu {
            id
            label
            path
            offset
          }
        }
      }
    }
  `);
  const { logo, navMenu } = data.rutinaJson.navbar;

  const [showFadeItems, setShowFadeItems] = React.useState(false);
  React.useEffect(() => {
    setTimeout(() => { setShowFadeItems(true) }, 200);
  }, []);

  const [state, setState] = useState({
    search: '',
    searchToggle: false,
    mobileMenu: false,
  });

  const searchRef = useRef(null);
  useOnClickOutside(searchRef, () =>
    setState({ ...state, searchToggle: false })
  );

  const toggleHandler = (type) => {
    // if (type === 'search') {
    //   setState({
    //     ...state,
    //     search: '',
    //     searchToggle: !state.searchToggle,
    //     mobileMenu: false,
    //   });
    // }

    if (type === 'menu') {
      setState({
        ...state,
        mobileMenu: !state.mobileMenu,
      });
    }
  };

  // const handleOnChange = (event) => {
  //   setState({
  //     ...state,
  //     search: event.target.value,
  //   });
  // };

  // const handleSearchForm = (event) => {
  //   event.preventDefault();

  //   if (state.search !== '') {
  //     console.log('search data: ', state.search);

  //     setState({
  //       ...state,
  //       search: '',
  //     });
  //   } else {
  //     console.log('Please fill this field.');
  //   }
  // };

  return (
    <NavbarWrapper className="navbar">
      <Container>
        <div className={'logo-container'}>
          <Logo
            className="logo"
            href="/#"
            logoSrc={logo.publicURL}
            title="Rutina"
            />
          <Text className="logo-title" content={'Rutina'} />
        </div>
        {/* end of logo */}

        <MenuArea className={state.searchToggle ? 'active' : ''}>
          <Fade when={showFadeItems}>
            <ScrollSpyMenu className="menu" menuItems={navMenu} offset={-84} />
          </Fade>
          {/* end of main menu */}

          {/* <Search className="search" ref={searchRef}>
            <form onSubmit={handleSearchForm}>
              <input
                type="text"
                value={state.search}
                placeholder="Enter your keyword"
                onChange={handleOnChange}
              />
            </form>
            <Button
              className="text"
              variant="textButton"
              icon={<Icon icon={state.searchToggle ? x : search} />}
              onClick={() => toggleHandler('search')}
            />
          </Search> */}
          {/* end of search */}

          {/* <AnchorLink href="#trail" offset={84}>
            <Button className="trail" title="Try for Free" />
          </AnchorLink> */}

          <Button
            className="menubar"
            icon={
              state.mobileMenu ? (
                <Icon className="bar" icon={x} />
              ) : (
                <Fade>
                  <Icon className="close" icon={menu} />
                </Fade>
              )
            }
            color="#0F2137"
            variant="textButton"
            onClick={() => toggleHandler('menu')}
          />
        </MenuArea>
      </Container>

      {/* start mobile menu */}
      <MobileMenu className={`mobile-menu ${state.mobileMenu ? 'active' : ''}`}>
        <Container>
          <ScrollSpyMenu className="menu" menuItems={navMenu} offset={-84} toggleHandler={() => toggleHandler('menu')} />
          {/* <Button title="Try for Free" /> */}
        </Container>
      </MobileMenu>
      {/* end of mobile menu */}
    </NavbarWrapper>
  );
};

export default Navbar;
